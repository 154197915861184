import { Role } from '@/auth/utils'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'Dashboard',
      code: 'DASHBOARD',
    },
  },

  {
    path: '/dashboard/g25',
    name: 'dashboard-g25',
    component: () => import('@/views/dashboards/g25-dashboard/DashboardG25.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'GLOBAL 25',
      code: 'G25-DASHBOARD',
    },
  },

  {
    path: '/dashboard/traits',
    name: 'dashboard-traits',
    component: () => import('@/views/results/traits-health/TraitResult.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'TRAITS & HEALTH',
      code: 'TRAIT-HEALTH',
    },
  },

  {
    path: '/dashboard/ibd',
    name: 'dashboard-ibd',
    component: () => import('@/views/results/ibd/IbdResult.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'IBD',
      code: 'IBD',
    },
  },

  {
    path: '/order/list',
    name: 'order-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/orders/MobileOrderList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/others/Notifications.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/kits/list',
    name: 'kits-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/kits/MobileKitsList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/ticket/list',
    name: 'ticket-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/MobileTicketList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  {
    path: '/ticket/new-ticket',
    name: 'new-ticket',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/NewTicket.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  {
    path: '/ticket/detail/:id',
    name: 'ticket-detail',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/TicketDetail.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  
  // Checkout
  {
    path: '/checkout/select-service',
    name: 'checkout-select-service',
    component: () => import('@/views/checkouts/select-services/CheckoutSelectService.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/checkout/g25/:step',
    name: 'checkout-g25',
    component: () => import('@/views/checkouts/DeepAncestryCheckout/DeepACheckout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/checkout/g25/:step/:product_code',
    name: 'checkout-g25',
    component: () => import('@/views/checkouts/DeepAncestryCheckout/DeepACheckout.vue'),
    meta: {
      layout: 'full',
    },
  },

  // {
  //   path: '/checkout/trait/:step/:product_code',
  //   name: 'checkout-trait',
  //   component: () => import('@/views/purchase/TraitCheckout.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },

  {
    path: '/checkout/g25-partner/:link_guid',
    name: 'checkout-trait',
    component: () => import('@/views/purchase-partner/g25/PartnerCheckout.vue'),
    meta: {
      layout: 'full',
    },
  },
  
  // Order Results
  {
    path: '/order-result/g25/:type',
    name: 'order-result-g25',
    component: () => import('@/views/results/g25/G25Result.vue'),
    meta: {
      contentClass: 'page-orders',
      authorize: [Role.Customer],
      pageTitle: 'G25 >',
      code: 'G25',
    },
  },

  {
    path: '/order-result/trait-detail/:id/:traitIds',
    name: 'order-result-trait-detail',
    component: () => import('@/views/results/traits-health/Traits/TraitDetail.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'TRAITS & HEALTH >',
      code: 'TRAIT-HEALTH',
    },
  },
]
